<!-- 青海健康云-登录 -->
<template>
	<div class="Login">
		<div class="Login_title">
			<img src="../assets/logo.png" alt="" style="width: 0.7rem;">
			<div>西宁市城北区数字政协管理平台</div>
		</div>
		<div class="Login_box">
			<div class="Login_box_title">
				{{title}}
			</div>
			
			<div class="Login_box_item" v-if='flag==0'>
				<user style="width: 0.4rem; height: 0.4rem;color: rgba(0, 225, 235,0.4);position: absolute;top: 0;bottom: 0;margin: auto;left: 0.05rem;"/>
				<input type="text" v-model="form.name" placeholder="请输入用户名" placeholder-style="color: #00E1EB;"/>
			</div>
			
			<div class="Login_box_item" v-if='flag==1'>
				<user style="width: 0.4rem; height: 0.4rem;color: rgba(0, 225, 235,0.4);position: absolute;top: 0;bottom: 0;margin: auto;left: 0.05rem;"/>
				<input type="text" v-model="form.name" placeholder="请输入手机号" placeholder-style="color: #00E1EB;"/>
			</div>
			<div class="Login_box_item" v-if='flag==1'>
				<lock style="width: 0.4rem; height: 0.4rem;color: rgba(0, 225, 235,0.4);position: absolute;top: 0;bottom: 0;margin: auto;left: 0.05rem;"/>
				<input type="text" v-model="form.code" placeholder="请输入验证码" placeholder-style="color: #00E1EB;"/>
				
				<el-button class="getcode" @click="send_code" :disabled='tips=="获取验证码"?false:true'>{{tips}}</el-button>
			</div>
			
			<div class="Login_box_item">
				<lock style="width: 0.4rem; height: 0.4rem;color: rgba(0, 225, 235,0.4);position: absolute;top: 0;bottom: 0;margin: auto;left: 0.05rem;"/>
				<input type="password" v-model="form.password" placeholder="请输入密码" placeholder-style="color: #00E1EB;"/>
			</div>
			
			<div style="width: 100%;">
				<el-button class="Login_box_btn" @click="url_Home">{{flag==0?'登录':'确定'}}</el-button>
				
				<div class="tips" @click="url_ForgetPassword">{{flag==0?'找回密码':'返回'}}</div>
			</div>
				
				<!-- <el-button class="Login_box_btn" @click="url_ForgetPassword">{{flag==0?'找回密码':'返回'}}</el-button> -->
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					name: '',
					password: '',
					code:''
				},
				flag:0,//0登录，1找回密码
				title:'用户登录',
				numbers:60,
				tips:'获取验证码'
			}
		},
		methods: {
			url_Home() {
				if(!this.form.name) {
					this.$message ({
					    message: "请输入用户名",//提示文案
					    type:"error",//提示类型
					    duration: 2000,//展示时长
					});
					return
				}
				if(!this.form.password) {
					this.$message ({
					    message: "请输入密码",//提示文案
					    type:"error",//提示类型
					    duration: 2000,//展示时长
					});
					return
				}
				var send_url=''
				let obj=null
				if(this.flag==1){//找回密码					
					if(!this.form.code) {
						this.$message ({
						    message: "请输入验证码",//提示文案
						    type:"error",//提示类型
						    duration: 2000,//展示时长
						});
						return
					}
					if(!this.form.password) {
						this.$message ({
						    message: "请输入密码",//提示文案
						    type:"error",//提示类型
						    duration: 2000,//展示时长
						});
						return
					}
					send_url='/admin/Login/findPwd'
					obj={
						code:this.form.code,
						phone: this.form.name,
						pwd: this.form.password
					}
				}else{//登录
					send_url='/admin/Login/login'
					obj={
						login_name: this.form.name,
						password: this.form.password
					}
				}
				if(this.form.name=='admin'&&this.form.password=='123456'){
						this.$message({
							message: "登录成功",//提示文案
							type:"success",//提示类型
							duration: 2000,//展示时长
						})
						setTimeout(()=>{
							this.$router.push('/tiandata')
						},1500)
				}else{
					this.$message({
						message: "账号或密码错误",//提示文案
						type:"error",//提示类型
						duration: 2000,//展示时长
					})
				}
				
				// this.$post({
				// 	url:send_url,
				// 	isLoading:true,
				// 	params:obj
				// 	}).then( res => {
				// 		this.$store.commit('setUserInfo',res)
				// 		localStorage.setItem('userInfo', JSON.stringify(res));
				// 		this.$router.push('/')
				// 	this.$message({
				// 		message: "登录成功",//提示文案
				// 		type:"success",//提示类型
				// 		duration: 2000,//展示时长
				// 	})
				// })
			},
			url_ForgetPassword(){
				if(this.flag==1){
					this.flag=0
					this.title='用户登录'
				}else{
					this.flag=1
					this.title='找回密码'
				}
				
			},
			send_code(){//获取验证码
				if(!this.form.name) {
					this.$message ({
					    message: "请输入手机号",//提示文案
					    type:"error",//提示类型
					    duration: 2000,//展示时长
					});
					return
				}
				var timer60s = setInterval(()=>{
					this.numbers--
					if(this.numbers==0){
						this.numbers=60
						this.tips = '获取验证码'
						clearInterval(timer60s)
					}else{
						this.tips = this.numbers+'秒后重新获取'
					}
				},1000)
				
				// this.$post({
				// 	url:'/admin/Login/GetCode',
				// 	isLoading:true,
				// 	params:{
				// 		phone: this.form.name
				// 	}}).then( res => {
					
				// 		this.$message({
				// 			message: "验证码已发送",//提示文案
				// 			type:"success",//提示类型
				// 			duration: 2000,//展示时长
				// 		})
				// 		var timer60s = setInterval(()=>{
				// 			this.numbers--
				// 			if(this.numbers==0){
				// 				this.numbers=60
				// 				this.tips = '获取验证码'
				// 				clearInterval(timer60s)
				// 			}else{
				// 				this.tips = this.numbers+'秒后重新获取'
				// 			}
				// 		},1000)
				// })
				
			}
			
		}
	}
</script>

<style lang="less" scoped>
	.Login{
		width: 100vw;
		height: 100vh;
		// background-image: url('../assets/img/Login_bg.png');
		// background-repeat: no-repeat;
		// background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.Login_title{
			margin-bottom: 2%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			div{
				color: #00E1EB;
				font-size: 0.27rem !important;
			}
		}
		
		.Login_box{
			background: rgba(11, 161, 248, 0.1) url("../assets/img/Login_box.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			padding: 0.2rem;
			height: 3rem;
			width: 3rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10vh;
			.Login_box_title{
				color: #00E1EB;
				font-size: 0.2rem;
				letter-spacing:0.07rem;
			}
			.Login_box_item{
				width: 100%;
				height: 17%;
				position: relative;
				input{
					padding-left: 0.2rem;
					width: 100%;
					height: 100%;
					border: 2px solid rgba(39,171,248,0.3);
					background: rgba(6, 135, 205, 0.3);
					color: rgba(0, 225, 235,0.4);
					border-radius: 0.1rem;
					font-size: 0.14rem;
				}
				input::-webkit-input-placeholder{ /*WebKit browsers*/
				color: rgba(0, 225, 235,0.4);
				}
				
				input::-moz-input-placeholder{ /*Mozilla Firefox*/
				color: rgba(0, 225, 235,0.4);
				}
				
				input::-ms-input-placeholder{ /*Internet Explorer*/ 
				color: rgba(0, 225, 235,0.4);
				}
				input:focus-visible{
					outline:none;
				}
				
			}
			.Login_box_btn{
				height: 5vh;
				width: 100%;
				font-size: 0.18rem;
				color: #fff;
				background: linear-gradient(0deg, #00B9D1, #6CEEFD);
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				margin-left: 0;
			}
		}
		.getcode{
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			border: none;
			color: #fff;
			background: linear-gradient(0deg, #00B9D1, #6CEEFD);
		}
	}
	.tips{
		margin-top: 0.5vh;
		width: 100%;
		text-align: right;
		color: #fff;
		font-size: 0.12rem;
		cursor: pointer;
	}
</style>
